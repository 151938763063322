
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        











































.contact-popup,
[class*='contact-popup--'] {
  @extend %box-shadow;

  overflow-y: scroll;
  width: 90vw;
  max-width: 69rem;
  max-height: 80vh;
  padding: $spacing * 1.5;
  background-color: $white;
  border-radius: $border-radius;

  @include mq(m) {
    padding: $spacing * 3;
  }
}

.contact-popup__intro {
  margin-bottom: $spacing;

  .icon {
    width: 4rem;
    height: 4rem;
    fill: $c-pink-medium;
  }

  @include mq(m) {
    margin-bottom: $spacing * 1.5;

    .icon {
      width: 6.4rem;
      height: 6.4rem;
    }
  }
}

.contact-popup__intro__title {
  margin: 0 0 $spacing * 0.75 0;
}
